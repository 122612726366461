<template>
  <div class="layout_width">
    <div class="body-2 mb-3 black white--text pa-2">
      「{{ key }}」の検索結果
    </div>
    <template v-for="(item, index) in items">
      <v-card class="mx-auto mb-3 pb-4">
        <v-card-text class="pb-0 caption">
          {{ kubun_name(item.kubun) }}
        </v-card-text>
        <router-link :to="ndc_link(no_escape(item.no),3,item.kubun)">
          <v-card-title class="pt-0 mb-0 pb-0">
            {{ item.no }}
            {{ text_clear(item.ndc_label) }}
          </v-card-title>
        </router-link>
        <v-card-text v-if="item.label" class="mt-0 pt-0 mb-0 pb-0" v-html="strong(text_clear(item.label), key)"></v-card-text>
      </v-card>
    </template>

    <infinite-loading ref="infiniteLoading" spinner="circle" @infinite="infiniteLoad">
      <v-alert slot="no-results" prominent type="warning">
        「{{ key }}」ではヒットしませんでした
      </v-alert>
      <v-alert slot="no-more" type="info" text class="mt-3 mb-6 ">
        「{{ key }}」の検索結果 合計 <strong>{{ items.length }}</strong> 件
      </v-alert>
    </infinite-loading>
  </div>
</template>
<script>
  import axios from 'axios'
  import InfiniteLoading from 'vue-infinite-loading';

  export default {
    components: {
      InfiniteLoading
    },
    data() {
      return {
        loading: false,
        key: String,
        target: String,
        items: [],
        page: 0,
        page_count: Number,
      }
    },
    methods: {
      infiniteLoad() {
        axios.get(this.API_PATH+'ndc_search.php?key='+this.key+'&target='+this.target+'&page='+this.page).then(response => {
          //ページ数確定(1ページ目)
          if(!this.items.length){
            this.page_cont = Math.floor(response.data.count/10)+1;
          }
          //APIコール毎処理
          this.page+=1;
          if(response.data.list.length !== 0){
            this.$refs.infiniteLoading.stateChanger.loaded();
            for(var tmp of response.data.list) {
              this.items.push(tmp);
            }
          }
          //終了判定
          if (this.page_cont <= this.page) {
            this.$refs.infiniteLoading.stateChanger.complete();
          }
        }).catch(error => {
          console.log(error)
        }).finally(() => this.loading = false)
      },
      reset: function (){
        this.key = this.$route.params.key;
        this.target = this.$route.params.target;
        this.page = 0;
        this.items = [];
        this.$refs.infiniteLoading.stateChanger.reset();
      },
    },
    mounted: function () {
      this.key = this.$route.params.key;
      this.target = this.$route.params.target;
    },
    watch: {
      '$route': function (to, from) {
        if (to.path !== from.path) {
          this.reset();
        }
      }
    }
  }
</script>
