<template>
  <v-footer padless>
    <v-col class="text-center" cols="12">
      {{ new Date().getFullYear() }} — <strong>{{TITLE}}</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data () {
    return {
      msg: 'footer'
    }
  }
}
</script>
