/* 定数 */
export default {
  data () {
    return {
      ROOT_PATH: this.root_path(),
      API_PATH: this.api_path(),
      TITLE: 'NDC Navi',
      TEXT_REPLACE: [
        ['．', '. '],
        ['＠', ''],
        ['@', ''],
        ['＃', ''],
        ['　', ' '],
        ['―', '-'],
      ],
      HOJO_KUBUN: [
        ['1', 'Ⅰ', '形式区分'],
        ['2', 'Ⅰ-a', '地理区分'],
        ['3', 'Ⅱ', '海洋区分'],
        ['4', 'Ⅲ', '言語区分'],
      ],
      /*
      OP1_LIST: {
        '0': '通常記号',
        '3': '二者択一記号',
        '5': '不使用分類記号',
        '6': '削除項目分類記号',
        '7': '削除二者択一分類記号',
      },
      OP2_LIST: {
        '0': '通常記号',
        '1': '中間見出し記号',
        '2': '範囲分類記号',
        '3': '繰り下げ記号(縮約記号)',
        '4': '二段繰り下げ記号(二重縮約記号)',
        '5': '三段繰り下げ記号(三重縮約記号)',
        '7': '繰り上げ記号(不均衡記号)',
        '8': '二段繰り上げ記号(二重不均衡記号)',
      },
      */
      OP1_LIST: {
        '1': '二者択一項目',
        '3': '不使用項目',
        '6': '削除項目',
        '7': '削除・二者択一項目',
      },
      OP2_LIST: {
        '1': '中間見出し',
        '2': '範囲項目',
      },
    }
  },
  methods: {
    //ドメインあれば相対でいける
    api_path: function(){
      //面倒なのでこれで
      return 'https://jasmine.omumlab.jp/ndc_navi10/api/';
      //return './api/';
      /*
      if(location.hostname === 'jasmine.media.osaka-cu.ac.jp'){
        return 'https://jasmine.media.osaka-cu.ac.jp/ndc_navi10/api/';
      } else if(location.hostname === 'ndcnavi.winspire.jp'){
          return 'https://ndcnavi.winspire.jp/api/';
      } else {
        return 'https://research/ndc10/api/';
      }
      */
    },
    //ドメインあれば相対でいける
    root_path: function(){
      if(location.hostname === 'jasmine.omumlab.jp'){
        return 'https://jasmine.omumlab.jp/ndc_navi10';
      } else {
        return '';
      }
    },
    depth_no: function(no, depth=3){
      if(depth < 3){
        return no = no.substr(0,depth+1);
      }
      return no;

    },
    ndc_link: function(no,depth,type='A'){
      if(type === 'A'){
        if(depth === 0){
          no = no.substr(0,1);
        } else if(depth === 1){
          no = no.substr(0,2);
        }
      }
      return '/'+no+'/'+(depth+1)+'/'+type;
    },
    text_clear: function(txt){
      for(var rep of this.TEXT_REPLACE) {
        txt = txt.replaceAll(rep[0],rep[1]);
      }
      return txt.trim();
    },
    strong: function(txt, key){
      return txt.replaceAll(key, '<strong>'+key+'</strong>');
    },
    br: function(txt){
      return txt.replaceAll('；','\n');
    },
    no_link: function(txt,kubun='A'){
      if(kubun === 'A'){
        return txt.replace(/([0-9]+\.[0-9]+|[0-9]{1,3})/gi, "<a href='"+this.ROOT_PATH+"#/$1/3/"+kubun+"'>$1</a>");
      } else {
        return txt;
      }
    },
    //ナンバーをエスケープ
    no_escape: function(no){
      return encodeURIComponent(no);
    },
    space: function(){
      return '\u00A0';
    },
    hojo_no_cut: function(no){
      if(no.indexOf('/') !== -1){
        let tmp = no.split('-');
        no = '－'+tmp[1]+'-'+tmp[2];
      } else if(no.indexOf('-') !== -1){
        let tmp = no.split('-');
        no = '－'+tmp[1];
      }
      return no;
    },
    hojo_no_cut_b: function(no){
      let tmp = no.split('-');
      no = '－'+tmp[1];
      return no;
    },
    ndc_label_space_hojo:function(no,op){
      if(no.indexOf('/') !== -1){
        let tmp = no.split('/');
        no = tmp[0];
      }
      let sp = '';
      for(let i=0; i<(no.length-1); i++){
        sp += '\u00A0\u00A0';
      }
      sp += this.ndc_label_space(op);

      return sp;
    },
    ndc_label_space: function(op){
      if(this.get_op(op,2) === '3'){
        return '\u00A0\u00A0\u00A0';
      } else if(this.get_op(op,2) === '4'){
        return '\u00A0\u00A0\u00A0\u00A0\u00A0';
      } else if(this.get_op(op,2) === '5'){
        return '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
      }
      return '';
    },
    kubun_name: function(kubun){
      if(kubun === 'A'){
        return '本表';
      } else if(kubun === 'B'){
        return '固有補助表';
      } else if(kubun === 'C'){
        return '一般補助表';
      }
    },
    //末尾のハイフンカット
    haifun_cut: function(no){
      if(no.charAt(no.length - 1) === '-'){
        return no.slice(0,-1);
      }
      return no;
    },
    haifun_big: function(no){
      console.log(no.replaceAll('-', "－"));
      return no.replaceAll('-', "－");
    },
    now_no_cut: function(no, now_no){
      return no.replace(now_no, '');
    },
    get_op: function(op, no){
      if(op !== undefined && op !== null){
        return op.split(',')[no];
      }
    },
    hojo_link_replace: function(str){
      for(var v of this.HOJO_KUBUN) {
        str = str.replaceAll(v[2], '<a href="' + this.ROOT_PATH + '/l' + v[0] +'">'+v[2]+'</a>');
      }
      return str;
    },
    hojo_top_no_replace: function(no){
      if(no.length === 1){
        for(var v of this.HOJO_KUBUN) {
          no = no.replaceAll(v[0], v[1]);
        }
      }
      return no;
    },
    hojo_top_en: function(item){
      if(item.no.length === 1){
        return item.label_en;
      }
      return '';
    },
    op_label: function(item){
      let ret = '';
      let op1 = this.get_op(item.op,1);
      let op2 = this.get_op(item.op,2);
      let op1_label = '';
      let op2_label = '';
      op1_label = this.OP1_LIST[op1];
      op2_label = this.OP2_LIST[op2];

      if(op1_label && op2_label){
        ret = op1_label + ', ' + op2_label;
      } else if(op1_label){
        ret = op1_label;
      } else if(op2_label){
        ret = op2_label;
      }
      return ret;
    },
    op1_label: function(item){
      let op = this.get_op(item.op,1);
      return this.OP1_LIST[op];
    },
    op2_label: function(item){
      let op = this.get_op(item.op,2);
      return this.OP2_LIST[op];
    }
  }
}
