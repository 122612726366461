<template>
  <div class="layout_width" v-if="list.length">
    <h1>日本十進分類法 - NDC10版 一般補助表</h1>

    <template v-for="(li, index) in list">
      <div :id="'l'+(index+1)" >&nbsp;</div>
      <table class="list2">
        <template v-for="(item, index) in li">
          <tr>
            <td class="list2_item">
              <router-link :to="ndc_link(no_escape(item.no),2,'C')">
                <div v-bind:class="{ kakko: is_hanni(item.no), 'title': is_top(item.no) }">
                  <span class="ndc_no pl-1" v-bind:class="{ kagi_kakko: get_op(item.op, 1) === '1' }">{{ hojo_top_no_replace(haifun_big(hojo_no_cut(item.no))) }}</span>
                  {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                  <span class="ndc_label">{{ text_clear(item.label) }} {{ hojo_top_en(item) }}</span>
                </div>


                <div class="hojo_note" v-if="item.descrption.trim() !== ''">
                  <div class="hojo_note_item_start">
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                  </div>
                  <div class="hojo_note_item_mid">
                    {{'\u00A0\u00A0\u00A0\u00A0'}}
                  </div>
                  <div class="hojo_note_item_text">
                    {{ text_clear(item.descrption) }}
                  </div>
                </div>

                <div class="hojo_note" v-if="item.note1.trim() !== ''">
                  <div class="hojo_note_item_start">
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                  </div>
                  <div class="hojo_note_item_mid">
                    ＊
                  </div>
                  <div class="hojo_note_item_text">
                    {{ haifun_big(text_clear(item.note1)) }}
                  </div>
                </div>

                <div class="hojo_note" v-if="item.note2.trim() !== ''">
                  <div class="hojo_note_item_start">
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                  </div>
                  <div class="hojo_note_item_mid">
                    ＊
                  </div>
                  <div class="hojo_note_item_text">
                    {{ haifun_big(text_clear(item.note2)) }}
                  </div>
                </div>

                <div class="hojo_note" v-if="item.note3.trim() !== ''">
                  <div class="hojo_note_item_start">
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                    {{ ndc_label_space_hojo(hojo_no_cut(item.no),item.op) }}
                  </div>
                  <div class="hojo_note_item_mid">
                    {{'\u00A0\u00A0\u00A0\u00A0'}}
                  </div>
                  <div class="hojo_note_item_text">
                    {{ haifun_big(text_clear(item.note3)) }}
                  </div>
                </div>

              </router-link>
            </td>
          </tr>
        </template>
      </table>
    </template>
  </div>
</template>

<style>
  .list2 {
    width: 100%;
    margin: 10px 0 0 0;
    border: solid 1px #000000; border-collapse: collapse;
  }
  .list2 caption {
    padding: 5px;
    text-align: left;
  }
  .list2_item {
    padding: 5px;
    border: solid 1px #000000;
    font-size: 12px;
  }
  .list2_item a {
    color: #000000 !important;
  }

  .kakko {
    display: inline-block;
  }
  .kakko::before {
  	content: '<';
  }
  .kakko::after {
  	content: '>';
  }

  .kagi_kakko {
    display: inline-block;
  }
  .kagi_kakko::before {
  	content: '[';
  }
  .kagi_kakko::after {
  	content: ']';
  }
  .top {
    font-weight: bold;
  }

  @page {
    size: auto;
  }

</style>


<script>
import axios from 'axios'

export default {
  name: 'ListC',
  data () {
    return {
      list: [],
    }
  },
  methods: {
    get_list: function(){
      axios.get(this.API_PATH+'list.php?type=C').then(response => {
        if(response.data.r === 'success'){
          this.list = response.data.list;
        } else if(response.data.r === 'error'){
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },


    ndc_space: function(no){
      no = this.haifun_cut(no);
      no = this.kubun_no(no);
      let len = 0;
      if(no.indexOf('/')){
        let tmp = no.split('/');
        len = tmp[0].length;
      } else {
        len = no.length;
      }
      //一文字目がハイフンの時
      if(no.slice(1) === '-'){
        len += 1;
      }
      //地理区分対策
      if(no === 'Ⅰ-a'){
        return '';
      }
      let sp = '';
      for(let i=0; i<len-1; i++){
        sp += '\u00A0\u00A0';
      }
      return sp;
    },
    no_label: function(item){
      let label = this.text_clear(item.label);
      let no = item.no;
      no = this.haifun_cut(no);
      no = this.kubun_no(no);
      if(no.indexOf('/') !== -1){
        return '<' + no +' '+ label + '>';
      } else {
        return no +' '+ label;
      }
    },
    kubun_no: function(no){
      //海洋区分対策(データ側の間違い)
      if(no === '3-'){
        no = '3';
      }
      if(no.length === 1){
        for(var rep of this.HOJO_KUBUN) {
          no = no.replace(rep[0], rep[1]);
        }
      } else {
        no = no.slice(1);
      }
      return no;
    },
    is_hanni(no){
      if(no.indexOf('/') !== -1){
        return true;
      }
      return false;
    },
    is_top(no){
      if(no.length === 1){
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    console.log('test');
    this.get_list();
  },
  updated: function () {
    let id = this.$route.params.scroll;
    if(id){
      let tag;
      tag = document.querySelector('#'+id);

      if(tag){
        tag.scrollIntoView(
          {
            behavior: 'smooth',
            block: 'center'
          }
        );
      }
    }
  }
}
</script>
