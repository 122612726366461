<template>
  <div class="layout_width">
    <template v-if="!loading">
      <template v-if="no && !ndc_data.no">
        <v-alert prominent type="error">
          <v-row align="center">
            <v-col class="grow">
              「{{no}}」はありません
            </v-col>
          </v-row>
        </v-alert>
      </template>
      <template v-else>
        <template v-if="ndc_data">
          <v-card class="mx-auto mb-6">
            <div v-if="type === 'A'" class="kubun">本表</div>
            <div v-if="type === 'B'" class="kubun">固有補助表</div>
            <div v-if="type === 'C'" class="kubun">一般補助表</div>

            <v-card-title class="headline">
              <template v-if="type === 'A'">
                {{ depth_no(ndc_data.no,depth) }}
              </template>
              <template v-if="type !== 'A'">
                {{ haifun_big(depth_no(ndc_data.no,depth)) }}
              </template>
              <template v-if="ndc_data.label">
                {{ text_clear(ndc_data.label) }}
              </template>
            </v-card-title>
            <v-card-text>
              <table class="data">
                <tr v-if="ndc_data.no">
                  <th>分類記号</th>
                  <td>
                    <template v-if="type === 'A'">
                      {{ depth_no(ndc_data.no,depth) }}
                    </template>
                    <template v-if="type !== 'A'">
                      {{ haifun_big(depth_no(ndc_data.no,depth)) }}
                    </template>
                  </td>
                </tr>
                <tr v-if="ndc_data.label">
                  <th>分類項目名</th>
                  <td>{{ text_clear(ndc_data.label) }}</td>
                </tr>
                <tr v-if="ndc_data.label_en">
                  <th>英語</th>
                  <td>{{ndc_data.label_en}}</td>
                </tr>
                <tr v-if="ndc_data.descrption">
                  <th>関連分類項目名</th>
                  <td>{{ text_clear(ndc_data.descrption) }}</td>
                </tr>

                <template v-if="type === 'A'">
                  <tr v-if="ndc_data.note1">
                    <th>注記</th>
                    <td class="pre" v-html="hojo_link_replace(no_link(br(text_clear(ndc_data.note1)),type))"></td>
                  </tr>
                  <tr v-if="ndc_data.note2">
                    <th>注参照</th>
                    <td class="pre" v-html="no_link(br(text_clear(ndc_data.note2)),type)"></td>
                  </tr>
                  <tr v-if="ndc_data.note3">
                    <th>参照</th>
                    <td class="pre" v-html="no_link(br(text_clear(ndc_data.note3)),type)"></td>
                  </tr>
                </template>
                <template v-if="type !== 'A'">
                  <tr v-if="ndc_data.note1">
                    <th>注記</th>
                    <td class="pre" v-html="hojo_link_replace(no_link(br(haifun_big(text_clear(ndc_data.note1))),type))"></td>
                  </tr>
                  <tr v-if="ndc_data.note2">
                    <th>注参照</th>
                    <td class="pre" v-html="no_link(br(haifun_big(text_clear(ndc_data.note2))),type)"></td>
                  </tr>
                  <tr v-if="ndc_data.note3">
                    <th>参照</th>
                    <td class="pre" v-html="no_link(br(haifun_big(text_clear(ndc_data.note3))),type)"></td>
                  </tr>
                </template>

                <tr v-if="op_label(ndc_data) !== ''">
                  <th>種類</th>
                  <td class="pre">
                    {{ op_label(ndc_data) }}
                  </td>
                </tr>
                <tr v-if="ndc_data.soukan">
                  <th>相関索引語</th>
                  <td>
                    <template v-for="soukan in ndc_data.soukan.split(',')">
                      <v-btn small class="ma-1" @click="soukan_search(soukan)">
                        {{ soukan }}
                        <v-icon right dark>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </table>
            </v-card-text>

            <template v-if="type === 'A' && no.indexOf('/') === -1">
              <v-divider class="mb-3"></v-divider>
              <v-card-title class="ma-0 pt-0 pb-1">
                <div class="text--primary">
                  <v-icon>mdi-magnify</v-icon>
                  この分類の本を探す
                </div>
              </v-card-title>
            </template>

            <v-card-actions v-if="type === 'A' && no.indexOf('/') === -1" class="pl-4 pt-0 pb-4">
              <a :href="'https://ndlsearch.ndl.go.jp/search?cs=bib&f-ndc='+ndl_no(no)" target="_blank">
                <v-btn color="indigo darken-3" class="white--text">
                  <div class="pl-2">
                    国立国会図書館サーチ
                  </div>
                </v-btn>
              </a>
              <form action="https://opac.ao.omu.ac.jp/webopac/ctlsrh.do?search_mode=detail" method="post" class="pl-2">
                <v-btn color="indigo darken-3" class="white--text" onclick="submit();">
                  <div class="pl-2">
                    大阪公立大学図書館OPAC
                  </div>
                </v-btn>
                <input type="hidden" name="initFlg" value="_SEARCH_COLUMNS_NOTCLS+_RESULT_SET">
                <input type="hidden" name="cls" :value="'NDC10:'+opac_no(no)">
                <input type="hidden" name="srhclm1" value="words">
                <input type="hidden" name="valclm1" value="">
                <input type="hidden" name="optclm1" value="AND">
              </form>

            </v-card-actions>
          </v-card>
        </template>
      </template>


      <template v-if="depth === 0">
        <div class="container">
          <h1>第1次区分表（類目表）</h1>
        </div>
      </template>


      <template>
        <template v-for="(item, index) in ndc_list">

          <template v-if="item.label !== ''">
            <template v-if="item.no.indexOf('/') !== -1">
              <router-link :to="ndc_link(no_escape(item.no),2,'A')" class="item">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="get_op(item.op, 2) !== '0'">
                      <div v-if="get_op(item.op, 2) === '1'" class="caption tag" >中間見出し</div>
                      <div v-if="get_op(item.op, 2) === '2'" class="caption tag" >範囲項目</div>
                    </v-list-item-subtitle>
                    <v-list-item-title class="title">
                      <span class="normal" v-if="get_op(item.op, 2) === '1'">{{ item.no }} {{ text_clear(item.label) }}</span>
                      <span class="normal" v-else>{{ item.no }} {{ text_clear(item.label) }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.label_en" class="caption">
                      <span class="normal black--text">{{ text_clear(item.note1) }}{{ text_clear(item.note2) }}{{ text_clear(item.note3) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </template>

            <template v-else-if="depth === 0">
              <router-link :to="ndc_link(item.no,depth)" class="item">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title">
                      <span class="normal">{{ depth_no(item.no,depth) }} {{ text_clear(item.label) }} </span>
                      <span class="normal yohaku">{{ text_clear(item.label_en) }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.label_en" class="caption">
                      <span class="normal black--text pre">{{ br(text_clear(top_text(item.no, item.note1))) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </template>

            <template v-else>
              <router-link :to="ndc_link(item.no,depth)" class="item">
                <v-list-item v-bind:class="{ del_bg: del_bg(item) }">
                  <v-list-item-content>
                    <v-list-item-subtitle v-if="get_op(item.op, 1) !== '0'">
                      <!--<div v-if="get_op(item.op, 1) === '1'" class="caption tag" >二者択一項目</div>-->
                      <div v-if="get_op(item.op, 1) === '3'" class="caption tag" >不使用項目</div>
                      <div v-if="get_op(item.op, 1) === '6'" class="caption tag" >削除項目</div>
                      <div v-if="get_op(item.op, 1) === '7'" class="caption tag" >削除・二者択一項目</div>
                    </v-list-item-subtitle>
                    <v-list-item-title class="title">
                      <span class="normal">{{ depth_no(item.no,depth) }} {{ text_clear(item.label) }} {{ del_info(item) }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.label_en" class="caption">
                      <span class="normal black--text">{{ text_clear(item.label_en) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </template>

            <v-divider></v-divider>
          </template>
        </template>
      </template>
      <v-container v-if="no" class="mt-6 mb-6">
        <v-btn block @click="back()">
          戻る
        </v-btn>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'NdcList',
  data () {
    return {
      loading: false,
      no: '',
      depth: Number,
      ndc: {},
      ndc_data: {},
      ndc_list: [],
    }
  },
  methods: {
    redraw: function(){
      //クエリを格納
      if(this.$route.params.no === undefined){
        this.no = '';
      } else {
        this.no = this.$route.params.no;
      }
      if(this.$route.params.depth !== undefined){
        this.depth = Number(this.$route.params.depth);
      } else {
        this.depth = 0;
      }
      if(this.$route.params.type === undefined){
        this.type = 'A';
      } else {
        this.type = this.$route.params.type;
      }
      this.get_list();
    },
    get_list: function(){
      this.loading = true;
      axios.get(this.API_PATH+'ndc.php'+this.query()).then(response => {
        if(response.data.r === 'success'){
          this.ndc_data = response.data.d;
          this.ndc_list = response.data.list;
          this.loading = false;
        } else if(response.data.r === 'error'){
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },
    query: function(){
      if(this.type !== 'A'){
        return '?no='+this.no+'&depth=0&type='+this.$route.params.type;
      } else if(this.no.indexOf('/') !== -1){
        return '?no='+this.no+'&depth=0&type='+this.$route.params.type;
      } else {
        if(this.no === undefined || this.no.length === 0){
          return '?no=root';
        } else {
          return '?no='+this.no;
        }
      }
    },
    soukan_search: function(soukan){
      this.$router.push('/search/'+soukan+'/0');
    },
    back: function(){
      this.$router.back();
    },
    del_info: function(item){
      let op = this.get_op(item.op, 1);
      if(op === '1' || op === '3' || op === '6' || op === '7'){
        return item.note3;
      } else {
        return '';
      }
    },
    del_bg: function(item){
      let op = this.get_op(item.op, 1);
      if(op === '3' || op === '6' || op === '7'){
        return true;
      }
      return false;
    },
    top_text: function(no, text){
      console.log(no);
      if(no === '800' || no === '900'){
        return '';
      } else {
        return text.split('\n')[1];
      }
    },
    ndl_no: function(no){
      if(no.length == 1){
        return no+'00';
      } else if(no.length == 2){
        return no+'0';
      } else {
        return no;
      }
    },
    opac_no: function(no){
      return no+'*';
    }
  },

  created: function () {
    this.redraw();
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        this.redraw();
      }
    }
  }

}
</script>


<style>
  .item {
    text-decoration: none;
  }
  .data {
    width: 100%;
    border: solid 1px #999999; border-collapse: collapse;
  }
  .data th {
    padding: 5px;
    background-color: #f0f0f0;
    border: solid 1px #999999;
    width: 160px;
    color: #000000;
  }
  .data td {
    padding: 5px;
    border: solid 1px #999999;
    color: #000000;
  }
  .kubun {
    background-color: #000000;
    color: #ffffff;
    padding: 8px;
    font-size: 12px;
  }
  .del_bg {
    background-color: #f5f5f5;
  }
  .tag {
    display: inline-block;
    background-color: #424242;
    color: white;
    padding: 0px 8px 0px 8px;
  }
  .yohaku {
    right: 0px;
    margin:0px 12px;
  }
</style>
