<template>
  <div v-if="list.length">
    <div class="container print_title">
      <h1>日本十進分類法 - NDC10版 3次区分表(細目表)</h1>
    </div>
    <template v-for="(p, p_index) in list">
      <div class="container">
        <router-link :to="ndc_link(p.no,0)">
          <h1>{{p_index}}類 {{p.label}}</h1>
        </router-link>
      </div>
      <div class="container">
        <table class="list" v-for="i of 5">
          <template v-for="(item, index) in p.child[i-1].child">
            <tr>
              <td v-if="item.label" class="list_item">
                <template v-if="item">
                  <template v-if="get_op(item.op, 1) === '0'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no pl-1">{{item.no}}</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '1'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">[{{item.no}}]</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}{{ cut3(item.note3) }}</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '6'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">({{item.no}}</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }})</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '7'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">([{{item.no}}]</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}){{ cut3(item.note3) }}</span>
                      </div>
                    </router-link>
                  </template>
                </template>
              </td>
              <td v-else class="list_item list_item_none" v-html="'&nbsp;'"></td>
            </tr>
          </template>
        </table>
      </div>
      <div class="container">
        <table class="list" v-for="i of 5">
          <template v-for="(item, index) in p.child[i+4].child">
            <tr>
              <td v-if="item.label" class="list_item">
                <template v-if="item">
                  <template v-if="get_op(item.op, 1) === '0'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no pl-1">{{item.no}}</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '1'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">[{{item.no}}]</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}{{ cut3(item.note3) }}</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '6'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">({{item.no}}</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }})</span>
                      </div>
                    </router-link>
                  </template>
                  <template v-if="get_op(item.op, 1) === '7'">
                    <router-link :to="ndc_link(item.no,2)">
                      <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                        <span class="ndc_no">([{{item.no}}]</span>
                        {{ ndc_label_space(item.op) }}
                        <span class="ndc_label">{{ text_clear(item.label) }}){{ cut3(item.note3) }}</span>
                      </div>
                    </router-link>
                  </template>
                </template>
              </td>
              <td v-else class="list_item list_item_none" v-html="'&nbsp;'"></td>
            </tr>
          </template>
        </table>
      </div>
      <div class="page_break"></div>
    </template>

  </div>
</template>

<style>
  .container h1 {
    margin-top: 20px;
  }
  .list caption {
    text-align: left;
    font-weight: bold;
    padding: 0 0 5px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .list_item {
    padding: 5px;
    border: solid 1px #000000;
    font-size: 12px;
    height: 20px;
  }
  .list_item_none {
    background-color: #EEEEEE;
  }
  .container a{
    color: #000000 !important;
  }
  .page_break  {
    page-break-before:always;
  }
</style>


<script>
import axios from 'axios'

export default {
  name: 'List3',
  data () {
    return {
      list: [],
    }
  },
  methods: {
    get_list: function(){
      axios.get(this.API_PATH+'list.php?type=3').then(response => {
        if(response.data.r === 'success'){
          this.list = response.data.list;
        } else if(response.data.r === 'error'){
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },
    cut3: function(str){
      if(str.indexOf('.') !== -1){
        return str.substr(0, 5);
      }
      return str;
    },
  },
  mounted: function () {
    this.get_list();
  },
}
</script>
