import Vue from 'vue'
import VueRouter from 'vue-router'
import NdcList from '../views/NdcList.vue'
import About from '../views/About.vue'
import List2 from '../views/List2.vue'
import List3 from '../views/List3.vue'
import ListC from '../views/ListC.vue'
import ListB from '../views/ListB.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter);

import Config from '../config.js'
Vue.mixin(Config);


//#を使う方法
export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: NdcList,
    },
    {
      path: '/about',
      name: 'About',
      component: About,
    },
    {
      path: '/list2',
      name: 'List2',
      component: List2,
    },
    {
      path: '/list3',
      name: 'List3',
      component: List3,
    },
    {
      path: '/list_b',
      name: 'ListB',
      component: ListB,
    },
    {
      //path: '/list_c/:scroll',
      path: '/list_c',
      name: 'ListC',
      component: ListC,
    },
    {
      path: '/search/:key/:target',
      name: 'Search',
      component: Search,
    },
    {
      path: '/:no/:depth/:type',
      name: 'NdcList',
      component: NdcList,
    },
  ]
})


/* #を使わない方法
const routes = [
  {
    path: '/',
    name: 'Home',
    component: NdcList,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/list2',
    name: 'List2',
    component: List2,
  },
  {
    path: '/list3',
    name: 'List3',
    component: List3,
  },
  {
    path: '/list_b',
    name: 'ListB',
    component: ListB,
  },
  {
    //path: '/list_c/:scroll',
    path: '/list_c',
    name: 'ListC',
    component: ListC,
  },
  {
    path: '/search/:key/:target',
    name: 'Search',
    component: Search,
  },
  {
    path: '/:no/:depth/:type',
    name: 'NdcList',
    component: NdcList,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
*/