<template>
  <div class="layout_width" v-if="list.length">
    <h1>日本十進分類法 - NDC10版 固有補助表</h1>
    <template v-for="(item, index) in list">
      <table class="list2">
        <tr>
          <td class="list2_item">
            <template>
              <router-link :to="ndc_link(no_escape(item.no),2,'B')">
                <span class="title">
                  {{ (index+1) + ') ' + item.label }}<br>
                  {{ title_fp(haifun_cut(item.no) + '\u00A0\u00A0' + item.label2, item.no) }}<br>
                </span>
                <div class="hojo_note" v-if="item.note1.split(/\n/)[1] !== ''">
                  <div class="hojo_note_item">
                    ＊
                  </div>
                  <div class="hojo_note_item">
                    {{ text_clear(item.note1.split(/\n/)[1]) }}
                  </div>
                </div>
                <div class="hojo_note" v-if="item.note1.split(/\n/)[2] !== undefined && item.note1.split(/\n/)[2] !== ''">
                  <div class="hojo_note_item">
                    ＊
                  </div>
                  <div class="hojo_note_item">
                    {{ text_clear(item.note1.split(/\n/)[2]) }}
                  </div>
                </div>
              </router-link>
            </template>
          </td>
        </tr>
        <tr v-for="(item2, index2) in list[index].child">
          <td class="list2_item">
            <router-link :to="ndc_link(no_escape(item2.no),2,'B')">
              <span class="ndc_no pl-1">{{ hojo_no_cut_b(item2.no) }}</span>
              {{ ndc_label_space_hojo(hojo_no_cut_b(item2.no),item2.op) }}
              <span class="ndc_label">{{ text_clear(item2.label) }}</span>

              <div class="hojo_note" v-if="item2.note1.split(/\n/)[1] !== undefined && item2.note1.split(/\n/)[1] !== ''">
                <div class="hojo_note_item">
                  {{ ndc_label_space_hojo(hojo_no_cut(item2.no),item2.op) }}
                </div>
                <div class="hojo_note_item">
                  ＊
                </div>
                <div class="hojo_note_item">
                  {{ haifun_big(text_clear(item2.note1.split(/\n/)[1])) }}
                </div>
              </div>
              <div class="hojo_note" v-if="item2.note1.split(/\n/)[2] !== undefined && item2.note1.split(/\n/)[2] !== ''">
                <div class="hojo_note_item">
                  {{ ndc_label_space_hojo(hojo_no_cut(item2.no),item2.op) }}
                </div>
                <div class="hojo_note_item">
                  ＊
                </div>
                <div class="hojo_note_item">
                  {{ haifun_big(text_clear(item2.note1.split(/\n/)[2])) }}
                </div>
              </div>

              <div class="hojo_note" v-if="item2.note2.trim() !== ''">
                <div class="hojo_note_item">
                  {{ ndc_label_space_hojo(hojo_no_cut(item2.no),item2.op) }}
                </div>
                <div class="hojo_note_item">
                  ＊
                </div>
                <div class="hojo_note_item">
                  {{ haifun_big(text_clear(item2.note2)) }}
                </div>
              </div>
              <div class="hojo_note" v-if="item2.note3.trim() !== ''">
                <div class="hojo_note_item">
                  {{ ndc_label_space_hojo(hojo_no_cut(item2.no),item2.op) }}
                </div>
                <div class="hojo_note_item">
                  ＊
                </div>
                <div class="hojo_note_item">
                  {{ haifun_big(text_clear(item2.note3)) }}
                </div>
              </div>

            </router-link>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ListB',
  data () {
    return {
      list: [],
    }
  },
  methods: {
    get_list: function(){
      axios.get(this.API_PATH+'list.php?type=B').then(response => {
        if(response.data.r === 'success'){
          this.list = response.data.list;
        } else if(response.data.r === 'error'){
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },

    ndc_space: function(no){
      let sp = '';

      if(no.indexOf('-') !== -1){
        let tmp = no.split('-');
        no = '-'+tmp[1];
        //space
        for(let i=0; i<no.length-1; i++){
          sp += '\u00A0\u00A0';
        }
      }
      return sp;
    },
    kubun_no: function(no){
      if(no.indexOf('-') !== -1){
        let tmp = no.split('-');
        no = '-'+tmp[1];
      }
      return no;
    },

    hojo_label: function(item){
      let label = this.text_clear(item.label);
      let no = item.no;
      no = this.haifun_cut(no);
      no = this.kubun_no(no);

      //space
      let sp = '';
      for(let i=0; i<no.length-1; i++){
        sp += '\u00A0\u00A0';
      }

      if(no.indexOf('/') !== -1){
        return '<' + no + sp + label + '>';
      } else {
        return no + sp + label;
      }
    },

    no_label: function(item){
      let label = this.text_clear(item.label);
      let no = item.no;
      no = this.haifun_cut(no);
      no = this.kubun_no(no);
      if(no.indexOf('/') !== -1){
        return '<' + no +' '+ label + '>';
      } else {
        return no +' '+ label;
      }
    },
    title_fp: function(label, no){
      if(no.indexOf('/') !== -1){
        return '<'+label+'>';
      } else {
        return label;
      }
    },
  },
  mounted: function () {
    console.log('listb');
    this.get_list();
  },
}
</script>
