<template>
  <v-app>
    <v-navigation-drawer v-model="sidebar" app temporary>
      <v-list>
        <v-list-item v-for="(item,index) in mobile_menus" :to="{ path:item.path }">
          <v-list-item-icon class="mr-4">
             <v-icon v-text="item.icon"></v-icon>
           </v-list-item-icon>
          <v-list-item-content>
            {{ item.title }}
          </v-list-item-content>
        </v-list-item>

        <a href="/ndcnavi9/" target="_blank">
          <v-list-item>
            <v-list-item-icon class="mr-4">
               <v-icon>mdi-numeric-9-circle</v-icon>
             </v-list-item-icon>
            <v-list-item-content>
              旧バージョン
            </v-list-item-content>
          </v-list-item>
        </a>
      </v-list>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <form :action="ROOT_PATH+'#/search/'+search2+'/'+search_target">
              <v-text-field
                v-model="search2" outlined
                prepend-inner-icon="mdi-magnify"
                append-icon="mdi-dots-vertical"
                @click:prepend-inner="input_search(search2)"
                @click:append="search_target_menu_show()"
                label="キーワード検索" hint="「007」などでも検索可能">
              </v-text-field>
            </form>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-app-bar app class="sys bar">
      <v-app-bar-nav-icon @click.stop="sidebar = !sidebar" class="hidden-sm-and-up"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-toolbar-title>
          <div id="title">{{TITLE}}</div>
          <div class="black--text text-caption">&nbsp;日本十進分類法10版</div>
        </v-toolbar-title>
      </router-link>

      <v-tabs align-with-title class="hidden-xs-only">
        <template v-for="(item,index) in menus">
          <v-tab :to="{ path: item.path }">
            {{item.title}}
          </v-tab>
        </template>
        <v-tab>
          <a href="/ndcnavi9/" target="_blank">
            <span class="menu_link_color">旧バージョン</span>
          </a>
        </v-tab>
      </v-tabs>

      <div class="hidden-xs-only">
        <form :action="ROOT_PATH+'#/search/'+search+'/'+search_target">
          <v-text-field class="search_input white"
            single-line v-model="search"
            outlined
            prepend-inner-icon="mdi-magnify"
            append-icon="mdi-dots-vertical"
            dense
            @click:prepend-inner="input_search(search)"
            @click:append="search_target_menu_show()"
            hide-details
            label="キーワードか分類記号で検索"
          >
          </v-text-field>
        </form>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <app-footer class="sys"/>

    <template>
      <div class="text-center">
        <v-dialog v-model="search_target_dialog" width="500">
          <v-card>
            <v-card-title class="text-h5 blue white--text pb-4">
              検索対象の選択
            </v-card-title>
            <v-card-text class="pt-3">
              <v-radio-group v-model="search_target">
                <v-radio label="相関索引" value="0"></v-radio>
                <v-radio label="分類項目" value="1"></v-radio>
                <v-radio label="分類項目+相関索引" value="2"></v-radio>
                <v-radio label="全て" value="3"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="search_target_menu_hide()">
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>


  </v-app>
</template>

<script>
import axios from 'axios'
import AppFooter from './components/AppFooter'

export default {
  name: 'App',
  components: {
    AppFooter,
  },

  data: () => ({
    sidebar: false,
    menus: [
      { title: 'HOME', path: '/' },
      { title: 'NDC Naviとは', path: '/about' },
      { title: '2次区分表', path: '/list2' },
      { title: '3次区分表', path: '/list3' },
      { title: '一般補助表', path: '/list_c' },
      { title: '固有補助表', path: '/list_b' },
    ],
    mobile_menus: [
      { title: 'HOME', path: '/', icon:'mdi-home' },
      { title: 'NDC Naviとは', path: '/about', icon:'mdi-help' },
      { title: '2次区分表', path: '/list2', icon:'mdi-text-box-outline' },
      { title: '3次区分表', path: '/list3', icon:'mdi-text-box-outline' },
      { title: '一般補助表', path: '/list_c', icon:'mdi-text-box-outline' },
      { title: '固有補助表', path: '/list_b', icon:'mdi-text-box-outline' },
    ],
    isLoading: false,
    items: [],
    model: null,
    search: null,
    search2: null,
    tab: null,
    auto_menu:false,
    search_target: '3',
    search_target_dialog: false,
  }),

  watch: {
    model (val) {
      if (val != null) this.tab = 0
      else this.tab = null
    },
    search (val) {
      this.search_action(val);
    },
  },
  methods: {
    back: function(){
      this.$router.back();
    },
    //検索周り
    search_action: function(val){
      this.isLoading = true;
      axios.get(API_PATH+'ndc_auto.php?key='+val).then(response => {
        this.items = response.data;
      }).catch(error => {
        console.log(error)
      }).finally(() => this.isLoading = false)
    },
    search_strong: function(label, value){
      return label.replaceAll(value, '<strong>'+value+'</strong>');
    },
    search_list_click: function(item){
      let path = '/'+item.no+'/3/'+item.kubun;
      if(path !== this.$route.path){
        this.$router.push('#/'+item.no+'/3/'+item.kubun);
      }
    },
    //検索アイコンクリック
    input_search: function(keyword){
      this.$router.push(ROOT_PATH+'#/search/'+keyword+'/'+this.search_target, ()=>{});
    },
    search_target_menu_show: function(){
      this.search_target_dialog = true;
    },
    search_target_menu_hide: function(){
      this.search_target_dialog = false;
    }
  },
  mounted: function () {
    if(this.$route.params.key){
      this.search = this.$route.params.key;
      this.search2 = this.$route.params.key;
    }
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        if(this.$route.params.key){
          this.search = this.$route.params.key;
          this.search2 = this.$route.params.key;
        }
        if(this.$route.params.target){
          this.search_target = this.$route.params.target;
        }
      }
    }
  }
};
</script>
<style lang="scss">
  @media screen and (min-width: 980px) {
    .layout_width {
      width: 980px;
      margin: 0 auto;
      padding: 5px;
    }
  }
  @media screen and (max-width: 980px) {
    .layout_width {
      width: 100%;
      margin: 0 auto;
      padding: 5px;
    }
  }

  .normal {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
  }
  .ndc_no {
    font-family: "Hiragino Kaku Gothic Pro", sans-serif !important;
  }
  .ndc_label {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
  }
  main {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
  }
  .pre {
    white-space: pre-wrap;
  }
  .search {
    width: 440px;
  }
  .search_width {
    width: 640px;
  }
  .search_subtitle {
    width: 480px;
    overflow: hidden;
  }

  .search_input {
    width: 400px;
  }

  #title {
    color: #0D47A1;
    margin-bottom: -5px;
  }
  .menu_link_color {
    color: #757575;
  }

  strong {
    color: black;
  }

  .hojo_note {
    display: flex;
    align-items: flex-start;
  }
  .hojo_note_item {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
  }
  .hojo_note_item_start {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
    padding-left: 20px;
    height: 16px;
    overflow: hidden;
  }
  .hojo_note_item_mid {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
  }
  .hojo_note_item_text {
    font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "メイリオ", sans-serif !important;
    width: 880px;
  }


  @page {
    size: A4 landscape;
  }
  @media print {
    .sys {
      display:none !important;
    }
    main {
      margin: 0 !important;
      padding:0 !important;
    }
    .print_title {
      display:none !important;
    }
    .container {
      padding:0;
    }
    .list_item {
      font-size: 10px !important;
    }
  }
</style>
