<template>
  <div v-if="list.length">
    <div class="container">
      <h1>日本十進分類法 - NDC10版 2次区分表(綱目表)</h1>
    </div>
    <div class="container">
      <table class="list" v-for="i of 5">
        <!--
        <caption>
          <router-link :to="ndc_link(list[i-1].child[0].no,0)">
            <h2>{{ i-1 }}類 {{list[i-1].child[0].label}}</h2>
          </router-link>
        </caption>
      -->
        <template v-for="(item, index) in list[i-1].child">
          <tr>
            <td class="list_item">
              <router-link :to="ndc_link(item.no,1)">
                <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                  <span class="ndc_no">{{ item.no.substr(0,2) }}</span>
                  {{ ndc_label_space(item.op) }}
                  <span class="ndc_label"> {{item.label}}</span>
                </div>
              </router-link>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="container">
      <table class="list" v-for="i of 5">
        <!--
        <caption>
          <router-link :to="ndc_link(list[i+4].child[0].no,0)">
            <h2>{{ i+4 }}類 {{list[i+4].child[0].label}}</h2>
          </router-link>
        </caption>
      -->
        <template v-for="(item, index) in list[i+4].child">
          <tr>
            <td class="list_item">
              <router-link :to="ndc_link(item.no,1)">
                <div v-bind:class="{ bold: get_op(item.op, 2) === '0' }">
                  <span class="ndc_no">{{ item.no.substr(0,2) }}</span>
                  {{ ndc_label_space(item.op) }}
                  <span class="ndc_label"> {{item.label}}</span>
                </div>
              </router-link>
            </td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<style>
/* pc */
@media screen and (min-width: 600px) {
  .container{
    display: flex;
    align-items: top;
    justify-content: center;
  }
  .list caption {
    text-align: left;
  }
  .list {
    width: 20%;
    margin: 10px 5px 0 5px;
    border: solid 1px #000000; border-collapse: collapse;
  }
  .list_item {
    padding: 5px;
    border: solid 1px #000000;
    font-size: 12px;
  }
  .list_item a {
    color: #000000 !important;
  }
  .bold {
    font-weight: bold;
  }
}
/* mobile */
@media screen and (max-width: 600px) {
  .list caption {
    text-align: left;
  }
  .list {
    width: 100%;
    margin: 10px 5px 0 0px;
    border: solid 1px #000000; border-collapse: collapse;
  }
  .list_item {
    padding: 5px;
    border: solid 1px #000000;
    font-size: 12px;
  }
  .list_item a {
    color: #000000 !important;
  }
  .bold {
    font-weight: bold;
  }
}
@media print {
  .container{
    display: flex;
    align-items: top;
    justify-content: center;
  }
  .list {
    width: 20%;
    margin: 10px 5px 0 5px;
    border: solid 1px #000000; border-collapse: collapse;
  }
  .list_item {
    padding: 5px;
    border: solid 1px #000000;
    font-size: 12px;
  }
  .list_item a {
    color: #000000 !important;
  }
  .bold {
    font-weight: bold;
  }
}


</style>


<script>
import axios from 'axios'

export default {
  name: 'List2',
  data () {
    return {
      list: [],
    }
  },
  methods: {
    get_list: function(){
      axios.get(this.API_PATH+'list.php?type=2').then(response => {
        if(response.data.r === 'success'){
          this.list = response.data.list;
        } else if(response.data.r === 'error'){
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    },
  },
  mounted: function () {
    this.get_list();
  },
}
</script>
